import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
} from '../actions';



export const fetchProducts = (venueId) => ({
  type: FETCH_PRODUCTS,
  payload: { venueId },
});
export const fetchProductsSuccess = (users) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: users,
});
export const fetchProductsError = (message) => ({
  type: FETCH_PRODUCTS_ERROR,
  payload: { message },
});


export const createUser = (user) => ({
  type: CREATE_USER,
  payload: { user },
});
export const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
});
export const createUserError = (message) => ({
  type: CREATE_USER_ERROR,
  payload: { message },
});
