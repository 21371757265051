import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
} from '../actions';

const INIT_STATE = {
  users: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // case CREATE_USER:
    //   return { ...state, loading: true, error: '' };
    // case CREATE_USER_SUCCESS:
    //   return { ...state, loading: false, error: '' };
    // case CREATE_USER_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload.message,
    //   };



    case FETCH_PRODUCTS:
      return { ...state, loading: true, error: '' };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
        error: '',
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return { ...state };
  }
};
