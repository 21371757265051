import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import "firebase/firestore";
import "firebase/storage";



import { firebaseConfig } from '../constants/defaultValues';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();
const db = firebase.firestore();
const storage = firebase.storage();


export { auth, database, db, storage };
