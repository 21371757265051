import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, db } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
const loginWithEmailPasswordAsync = async (email, password) =>
  new Promise(async (res, rej) => {
    await auth
      .signInWithEmailAndPassword(email, password)
      .then((authUser) => res({ status: true, data: authUser }))
      .catch((error) => res({ status: false, message:'login error' }));
  })


const checkAccount = async (email, password) => {
  return new Promise((resolve, reject) => {
    db.collection("Accounts").doc(email).get().then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data());
        const uData = doc.data()
        if (uData.password === password) {
          resolve({ status: true, data: { ...uData, email: email } })
        } else {
          resolve({ status: false })
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        resolve({ status: false })
      }
    }).catch((error) => {
      reject(error)
      console.log("Error getting document:", error);
    });
  })


}
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginRes = yield call(loginWithEmailPasswordAsync, email, password);
    // console.log('loginUser', userActive.data)
    if (loginRes.status) {
      const loginUser = loginRes?.data;
      yield localStorage.setItem('user_id', loginUser.user.uid);
      const userActive = yield call(checkAccount, email, password)
      console.log('userData', userActive.data)
      yield localStorage.setItem('userData11', JSON.stringify(userActive.data));
      yield put(loginUserSuccess({ ...loginUser.user, userData: userActive.data, venueId: userActive?.data?.venueId }));
      history.push('/');
    } else {
      const userActive = yield call(checkAccount, email, password)
      if (userActive.status) {
        console.log('no user found ')
        const registerRes = yield call(registerWithEmailPasswordAsync, email, password);
        console.log('registerUser', registerRes)
        if (registerRes.status) {
          const registerUser = registerRes?.data
          yield localStorage.setItem('user_id', registerUser.user.uid);
          console.log('userData', userActive.data)
          yield localStorage.setItem('userData12', JSON.stringify(userActive.data));
          yield put(registerUserSuccess({ ...registerUser.user, userData: userActive.data, venueId: userActive.data.venueId }));;
          history.push('/');
        } else {
          yield put(registerUserError(registerRes.message));
        }

      } else {
        console.log('login error')
        yield put(loginUserError(loginRes.message));
      }
    }
  } catch (error) {
    console.log('login Exception')
    console.log('login Exception', JSON.stringify(error.message))
    yield put(loginUserError(JSON.stringify(error)));
  }
}



export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  new Promise(async (res, rej) => {
    await auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => (console.log('resolve'), res({ status: true, data: authUser })))
      .catch((error) => (console.log('error'), res({ status: false, message: typeof (error) === String ? error : JSON.stringify(error) })));
  })

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem('user_id', registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push('/');
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('user_id');
    localStorage.removeItem('userData');
  } catch (error) { }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
