import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { auth, database, db } from '../../helpers/Firebase';
import {
  CREATE_USER,
  FETCH_PRODUCTS,
} from '../actions';

import {
  createUserSuccess,
  createUserError,
  fetchProductsSuccess,
  fetchProductsError,
} from './actions';



// export function* watchCreateUser() {
//   yield takeEvery(CREATE_USER, createUser);
// }
// const createUserAsync = async (user) => {
//   return new Promise((res, rej) => {
//     db.collection("Accounts").doc(user.email).set(user)
//       .then(() => res(true))
//       .catch(err => console.log('err', JSON.stringify(err), rej(err)))
//   })
// }

// function* createUser({ payload }) {
//   try {
//     const loginUser = yield call(createUserAsync, payload.user);
//     if (!loginUser.message) {
//       const users = yield call(fetchProductsAsync);
//       if (!users.message) {
//         // yield put(fetchUsersSuccess(users));
//       } else {
//         yield put(createUserSuccess(loginUser.user));
//       }
//     } else {
//       yield put(createUserError(loginUser.message));
//     }
//   } catch (error) {
//     yield put(createUserError(error));
//   }
// }



export function* watchFetchProducts() {
  yield takeLatest(FETCH_PRODUCTS, fetchProductsG);
}

const fetchProductsAsync = async (venueId) => {
  const products = []
  await db.collection("Products")
    .where("venueId", "==", venueId)
    .get()
    .then((querySnapshot) => {

      querySnapshot.forEach((doc) => {
        const document = doc.data()
        products.push({
          ...document, id: doc.id, priceShow: '£ ' + document.price,
          img: 'products/' + venueId + '/' + doc.id + '.png'
        })
      });
    })
    .catch(error => {
      console.log('error', error)
    })
  return products
}
function* fetchProductsG({ payload }) {
  console.log('venue id in saga', payload)
  try {
    const usersRes = yield call(fetchProductsAsync, payload.venueId);
    if (!usersRes.message) {
      yield put(fetchProductsSuccess(usersRes));
    } else {
      yield put(fetchProductsError(usersRes.message));
    }
  } catch (error) {
    yield put(fetchProductsError(error));
  }
}




export default function* rootSaga() {
  yield all([
    // fork(watchCreateUser),
    fork(watchFetchProducts),
  ]);
}
