import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth, database, db } from '../../helpers/Firebase';
import {
  CREATE_USER,
  FETCH_USERS,
} from '../actions';

import {
  createUserSuccess,
  createUserError,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersError,
} from './actions';



export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUser);
}
const createUserAsync = async (user) => {
  return new Promise((res, rej) => {
    db.collection("Accounts").doc(user.email).set(user)
      .then(() => res(true))
      .catch(err => console.log('err', JSON.stringify(err), rej(err)))
  })
}

function* createUser({ payload }) {
  try {
    const loginUser = yield call(createUserAsync, payload.user);
    if (!loginUser.message) {
      const users = yield call(fetchUsersAsync);
      if (!users.message) {
        yield put(fetchUsersSuccess(users));
      } else {
        yield put(createUserSuccess(loginUser.user));
      }
    } else {
      yield put(createUserError(loginUser.message));
    }
  } catch (error) {
    yield put(createUserError(error));
  }
}



export function* watchFetchUsers() {
  yield takeEvery(FETCH_USERS, fetchUsersG);
}
const fetchUsersAsync = async (venueId, email) => {
  const users = []
  await db.collection("Accounts")
    .where("venueId", "==", venueId)
    // .where("type", "!==", 'owner')
    // .where("email", "!==", email)
    .get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const document = doc.data()
        if ((document.type === 'member' && document.email !== email) ) {
          users.push({ ...document, id: doc.id })
        }
      });
    });
  return users
}
function* fetchUsersG({ payload }) {
  try {
    const usersRes = yield call(fetchUsersAsync, payload.venueId, payload.email);
    if (!usersRes.message) {
      yield put(fetchUsersSuccess(usersRes));
    } else {
      yield put(fetchUsersError(usersRes.message));
    }
  } catch (error) {
    yield put(fetchUsersError(error));
  }
}




export default function* rootSaga() {
  yield all([
    fork(watchCreateUser),
    fork(watchFetchUsers),
  ]);
}
