import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
} from '../actions';



export const fetchUsers = (venueId,email) => ({
  type: FETCH_USERS,
  payload: { venueId, email },
});
export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});
export const fetchUsersError = (message) => ({
  type: FETCH_USERS_ERROR,
  payload: { message },
});


export const createUser = (user) => ({
  type: CREATE_USER,
  payload: { user },
});
export const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
});
export const createUserError = (message) => ({
  type: CREATE_USER_ERROR,
  payload: { message },
});
